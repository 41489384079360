/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

import './src/styles/global.css'
import { GatsbyBrowser } from 'gatsby'

declare global {
  interface Window {
    dataLayer: { push: (event: any) => void };
  }
}

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  console.log("onRouteUpdate called for:", location.pathname);
  
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer.push({
      event: "gatsby-route-change",
      page: {
        path: location.pathname,
        search: location.search,
        title: document.title,
      },
    });
  }
};
